import styled from "styled-components";
import media from "../../../lib/media";

export const Wrap = styled.div`
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
`

export const Inner = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;

    padding: 60px 0;

    ${media.tablet`
        padding: 60px 20px;
        box-sizing: border-box;
        
        flex-direction: column;
    `};

    ${media.mobile`
        padding: 40px 20px;
        gap: 60px;
    `};
`

export const NoticeItemWrap = styled.div`
    width: 100%;
    min-height: 252px;
    padding: 24px 26px 40px 24px;
    box-sizing: border-box;
    border: 1px solid #CBCBCB;
    border-radius: 20px;
    overflow: hidden;

    ${media.mobile`
        padding: 0;
        border: none;
        border-radius: 0;
    `};
`

export const TitleBx = styled.div`
    border-bottom: 2px solid #C6151B;
    padding-bottom: 14px;
    margin-bottom: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
        font-weight: 700;
        font-size: 30px;
        line-height: 42px;
        color: #0F0F10;
    }

    a {
        color: #0F0F10;
        font-size: 18px;
        font-weight: 600;
    }

    ${media.tablet`
        padding-bottom: 10px;
        margin-bottom: 30px;
        h3{
            font-size: 24px;
            line-height: 33px;
        }
        
        a{
            font-size: 16px;
        }
    `};
`

export const NoticeList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    ${media.mobile`
        gap: 20px;
    `};
`

export const NoticeInfo = styled.dl`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    dt {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #0F0F10;
    }

    dd {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #666666;
    }

    ${media.mobile`
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        
        dt{
            font-size: 20px;
            line-height: 28px;
        }
        dd{
            font-size: 14px;
            line-height: 19px;
        }
    `};
`

export const Empty = styled.div`
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 108px;
`