import Layout from "../../../components/layout";
import NewsList from "../../../components/news/news-list";

const NewsListPage = () => {
    return (
        <Layout>
            <NewsList/>
        </Layout>
    )
}

export default NewsListPage;