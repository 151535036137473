import NoticeItems from "./notice-items";
import * as S from "./styles"
import {useNewsMainlist} from "../../../api/useNews";
import {useNoticeMainList} from "../../../api/useNotice";

const Notice = () => {
    const newsList = useNewsMainlist();
    const noticeList = useNoticeMainList();

    return (
        <S.Wrap>
            <S.Inner>
                {
                    newsList &&
                    <NoticeItems
                        title={"공지사항"}
                        link={'/news/content/'}
                        items={newsList}
                        more={'/news/list'}
                    />
                }
                {
                    noticeList &&
                    <NoticeItems
                        title={"교육 & 펀다이빙 문의"}
                        link={'/notice/content/'}
                        items={noticeList}
                        more={'/notice/list'}
                    />
                }
            </S.Inner>
        </S.Wrap>
    )
}

export default Notice;