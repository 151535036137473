import NoticeList from "../../../components/notice/notice-list";
import Layout from "../../../components/layout";

const NoticeListPage = () => {
    return (
        <Layout>
            <NoticeList/>
        </Layout>
    )
}

export default NoticeListPage;