import styled from "styled-components";

export const Wrap = styled.div`

`

export const Inner = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 100px 0;
`

export const TitleBx = styled.div`
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        color: #333;
        letter-spacing: 1px;
        margin-bottom: 20px;
    }

    p {
        margin-top: 10px;
        text-align: center;
        color: #666;
        font-size: 16px;
        line-height: 1.5;
        word-break: keep-all;
    }
`

export const ContentBx = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    a {
        display: inline-block;
        width: 200px;

        img {
            width: 100%;
        }
    }
`


