import * as S from "./styles";
import Board from "../../common/board";
import {useMyNotice} from "../../../api/useNotice";

interface Props {
    userId: number;
}

const MyNotice = ({userId}: Props) => {
    const list = useMyNotice(userId);
    const onDelete = async (id: string) => {
        try {

        } catch (error) {
            console.log(error);
        }
    }

    if (!list) return null;

    return (
        <S.Wrap>
            <S.Inner>
                <Board list={list} link={"/notice/content/"}></Board>
            </S.Inner>
        </S.Wrap>
    )
}

export default MyNotice;