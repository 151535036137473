import TechnicalInfo from "../../../components/recreation/technical-info";
import Layout from "../../../components/layout";

const TechnicalInfoPage = () => {
    return (
        <Layout>
            <TechnicalInfo/>
        </Layout>
    )
}

export default TechnicalInfoPage;