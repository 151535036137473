import CompleteContentBx from "../../../components/complete-content-bx";
import Layout from "../../../components/layout";

const CompletePage = () => {
    return (
        <Layout>
            <CompleteContentBx/>
        </Layout>
    )
}

export default CompletePage;