import React, {useEffect, useState} from "react";
import * as S from "./styles";
import moment from "moment/moment";
import {motion, AnimatePresence} from "framer-motion";
import {useNavigate, useParams} from "react-router-dom";
import BtnDefault from "../../common/btn-default";
import PopupPassword from "./popup-password";
import {Link} from "react-router-dom";
import Reply from "./reply";
import 'react-quill/dist/quill.snow.css';
import {useAuthContext} from "../../auth-provider";
import {useNoticeContent, useNoticePassword} from "../../../api/useNotice";
import {useQueryClient} from "@tanstack/react-query";

const NoticeContent = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const queryClient = useQueryClient();

    const {user} = useAuthContext();
    const [password, setPassword] = useState<string>('');
    const [isPassword, setIsPassword] = useState<boolean | null>(null);

    const data = useNoticeContent(id);
    const {data: passwordData, refetch: checkPassword} = useNoticePassword(id, password);

    useEffect(() => {
        if (data) {
            if (user) {
                if (user.admin === 1) {
                    setIsPassword(false);
                } else {
                    setIsPassword(!(data.user_id === user.id));
                }
            } else {
                setIsPassword(true);
            }
        }
    }, [data, user]);

    useEffect(() => {
        return () => {
            queryClient.removeQueries({queryKey: ['notice-password']})
        }
    }, []);

    const onClick = () => {
        if (password === '') {
            alert("패스워드를 입력해주세요");
            return;
        }

        checkPassword();
    }

    useEffect(() => {
        if (passwordData) {
            if (passwordData.isPassword) {
                setIsPassword(false);
            } else {
                alert("비밀번호가 일치하지 않습니다.");
                navigate('/notice/list');
            }
        }
    }, [passwordData]);

    if (!data) return null;

    return (
        <S.Wrap>
            <S.Inner>
                <S.NoticeContainer>
                    <S.NoticeHeader>
                        <dl>
                            <dt>문의 내용</dt>
                        </dl>
                    </S.NoticeHeader>
                </S.NoticeContainer>

                <AnimatePresence mode={'wait'}>
                    {
                        !isPassword ? (
                            <motion.div
                                key={'notice-key'}
                                initial={{y: 10, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                exit={{y: 10, opacity: 0}}
                                transition={{
                                    duration: 0.3,
                                    ease: 'easeOut'
                                }}
                            >
                                {
                                    data && <>
                                        <S.NoticeBx>
                                            <S.NoticeTitle>
                                                <span>{data.title}</span>
                                                <span>{moment(data.createDate).format("YYYY/MM/DD")}</span>
                                            </S.NoticeTitle>
                                            <S.NoticeContent
                                                className={'ql-editor'}
                                                dangerouslySetInnerHTML={{__html: data.content}}
                                            />
                                            {
                                                data.orderId && <S.OrderLinkBx>
                                                    <Link to={`/expense/complete/${data.orderId}`} target={"_blank"}>
                                                        온라인 견적 확인
                                                    </Link>
                                                </S.OrderLinkBx>
                                            }
                                        </S.NoticeBx>

                                        <Reply noticeId={data.id}></Reply>

                                        <S.BtnBx>
                                            <BtnDefault link={"/notice/list"} title={'확인'}/>
                                        </S.BtnBx>
                                    </>
                                }
                            </motion.div>
                        ) : (
                            <motion.div
                                initial={{y: 10, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                exit={{y: -10, opacity: 0}}
                                transition={{
                                    duration: 0.3,
                                    ease: 'easeOut'
                                }}
                            >
                                <PopupPassword
                                    setPassword={setPassword}
                                    onClick={onClick}
                                />
                            </motion.div>
                        )
                    }
                </AnimatePresence>
            </S.Inner>
        </S.Wrap>
    )
}

export default NoticeContent;