import styled from "styled-components";

export const Wrap = styled.div`
    
`

export const Inner = styled.div`
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding: 100px 0;
`

export const ContentBx = styled.div`
    
`

export const TitleBx = styled.div`
    h2{
        text-align: center;
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 100px;
    }
`