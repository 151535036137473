import Technical from "../../components/technical";
import Layout from "../../components/layout";

const TechnicalPage = () => {
    return (
        <Layout>
            <Technical/>
        </Layout>
    )
}

export default TechnicalPage;