import styled from "styled-components";
import DiverVisual from "../../common/diver-visual";
import DiverLesson from "../../common/diver-lesson";
import {FundivingLodgmentData, FundivingLodgmentContentData} from "./data";
import DiverInfo from "../../common/diver-info";
import BtnPayment from "../../common/btn-payment";

const Wrap = styled.div`
`

const FundivingLodgment = () => {
    return(
        <Wrap>
            <DiverVisual imgSrc={'/image/img_diver_visual.png'}/>
            <DiverLesson info={FundivingLodgmentData}/>
            <DiverInfo info={FundivingLodgmentContentData}/>
            <BtnPayment />
        </Wrap>
    )
}

export default FundivingLodgment;