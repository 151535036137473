import {ContentType} from "../../../types/my";
import * as S from "./styles";
import {useAuthContext} from "../../auth-provider";

interface Props {
    user: any;
    onClick: (type: keyof ContentType) => void;
}

export const UserInfo = ({user, onClick}: Props) => {
    const {logout} = useAuthContext();
    return (
        <S.UserDataBx>
            <dl onClick={() => onClick("my")}>
                <dt>마이페이지</dt>
                <dd>
                    <h2>
                        {user.userName}
                        <span>
                            {user.admin === 1 && '(운영자)'}님 안녕하세요!
                        </span>
                    </h2>
                </dd>
            </dl>
            <S.Logout>
                <button onClick={logout}>로그아웃</button>
            </S.Logout>
        </S.UserDataBx>
    )
}

export default UserInfo;