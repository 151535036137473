import {TeamMaxStoreType} from "../../../types/main";
import * as S from "./styles";
import {Link} from "react-router-dom";
import {useInAction} from "../../../hooks/useInAction";
import moment from "moment";

interface Props {
    item: TeamMaxStoreType
}

const StoryItem = ({item}: Props) => {
    const {ref, controls, variants} = useInAction();

    return (
        <Link to={`/story/content/${item.id}`} ref={ref}>
            <S.ItemWrap
                initial={"hidden"}
                animate={controls}
                variants={variants}
            >
                <S.ImgBx>
                    <img src={item.url} alt=""/>
                </S.ImgBx>
                <dl>
                    <dt>{ item.title }</dt>
                    <dd>
                        {
                            moment(item.createdAt).format('YYYY. MM. DD')
                        }
                    </dd>
                </dl>
            </S.ItemWrap>
        </Link>
    )
}

export default StoryItem;