import * as S from "./styles";
import {useLocation} from "react-router-dom";
import {useAuthContext} from "../auth-provider";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWater} from "@fortawesome/free-solid-svg-icons";
import {baseURL} from "../../api/axios";

const Login = () => {
    const {user} = useAuthContext();
    const navigate = useNavigate();
    const location = useLocation();
    const referer = new URLSearchParams(location.search).get("referer") || "";

    useEffect(() => {
        if (user) navigate(referer);
    }, [user]);

    return (
        <S.Wrap>
            <S.Inner>
                <S.TitleBx>
                    <h2><FontAwesomeIcon icon={faWater}/> TEAMMAXDIVE</h2>
                    <p>팀맥스다이브는 다이빙 전문 커뮤니티 플랫폼입니다</p>
                    <p>간편하게 로그인하고 서비스를 이용해보세요</p>
                </S.TitleBx>

                <div></div>

                <S.ContentBx>
                    <a href={`${baseURL}/login/oauth/kakao?referer=${referer}`}>
                        <img src="/image/btn_login_kakao.png" alt=""/>
                    </a>
                    <a href={`${baseURL}/login/oauth/naver?referer=${referer}`}>
                        <img src="/image/btn_login_naver.png" alt=""/>
                    </a>
                    <a href={`${baseURL}/login/oauth/google?referer=${referer}`}>
                        <img src="/image/btn_login_google.png" alt=""/>
                    </a>
                </S.ContentBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default Login;
