import styled from "styled-components";
import media from "../../../lib/media";

export const Wrap = styled.div`
    padding: 100px 0;
    ${media.mobile`
        padding-top: 30px;
    `};
`

export const Inner = styled.div`
    max-width: 800px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 50px;
    box-sizing: border-box;

    ${media.tablet`
        padding: 0 20px;
    `};
`

export const Container = styled.div`

`

export const Header = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 70px;

    dl {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        dt {
            font-size: 24px;
            font-weight: 700;
        }

        dd {
            font-size: 18px;
            font-weight: 700;
        }
    }
`

export const Bx = styled.div`
    box-sizing: border-box;
    border-top: none;
`

export const Title = styled.div`
    border-bottom: 1px solid #cecece;
    margin-bottom: 10px;

    input {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        font-size: 22px;
        border: none;

        &::placeholder {
            color: gray;
        }
    }
`

export const PasswordBx = styled.div`
    margin-top: 20px;

    > dl {
        > dt {
            display: flex;
            justify-content: flex-end;

            input {
                padding: 5px;
                box-sizing: border-box;
                font-size: 16px;
                font-weight: 600;
                border: none;
                border-bottom: 1px solid #cecece;

                &::placeholder {
                    color: gray;
                }
            }
        }

        > dd {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            margin-top: 50px;
        }
    }
`