import Layout from "../../../components/layout";
import StoryWrite from "../../../components/story/story-write";

const StoryWritePage = () => {
    return (
        <Layout>
            <StoryWrite />
        </Layout>
    )
}

export default StoryWritePage;