import styled from "styled-components";
import media from "../../../lib/media";

export const Wrap = styled.div`
    padding: 100px 0;
    ${media.mobile`
        padding-top: 30px;
    `};
`

export const Inner = styled.div`
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 50px;
    box-sizing: border-box;

    ${media.tablet`
        padding: 0 20px;
    `};
`

export const NoticeHeader = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 70px;

    dl {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        dt {
            font-size: 24px;
            font-weight: 700;
        }

        dd {
            font-size: 18px;
            font-weight: 700;
        }
    }
`

export const ContentListBx = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid #cecece;
`

export const BtnBx = styled.div`
    text-align: center;
`