import {Link} from "react-router-dom";
import * as S from "./styles"
import moment from "moment";

interface Props {
    title: string;
    link: string;
    items: any[];
    more: string;
}

const NoticeItems = ({title, link, items, more}: Props) => {
    return (
        <S.NoticeItemWrap>
            <S.TitleBx>
                <h3>{title}</h3>
                <Link to={more}>+ 더보기</Link>
            </S.TitleBx>
            <S.NoticeList>
                {
                    items.length > 1 ? (
                        items.map((item, idx) => (
                            <Link to={`${link}${item.id}`} key={`notice-item-${idx}`}>
                                <S.NoticeInfo>
                                    <dt>{item.title}</dt>
                                    <dd>
                                        {
                                            moment(item.createdAt).format('YYYY/MM/DD')
                                        }
                                    </dd>
                                </S.NoticeInfo>
                            </Link>
                        ))
                    ) : (
                        <S.Empty>게시글이 없습니다.</S.Empty>
                    )
                }
            </S.NoticeList>
        </S.NoticeItemWrap>
    )
}

export default NoticeItems;