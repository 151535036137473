import Resort from "../../../components/team-max/resort";
import Layout from "../../../components/layout";

const ResortPage = () => {
    return (
        <Layout>
            <Resort/>
        </Layout>
    )
}

export default ResortPage;