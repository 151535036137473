import OpenwaterFun from "../../../components/recreation/openwater-fun";
import Layout from "../../../components/layout";

const OpenWaterFunPage = () => {
    return (
        <Layout>
            <OpenwaterFun/>
        </Layout>
    )
}

export default OpenWaterFunPage;