import StoryItem from "./story-item";
import * as S from "./styles";
import TitleBx from "../../common/title-bx";
import {useStoryMainList} from "../../../api/useStory";

const TeamMaxStory = () => {
    const data = useStoryMainList();
    
    if (!data) return null;

    return (
        <S.Wrap>
            <S.Inner>
                <TitleBx
                    title={'TEAM MAX STORY'}
                    description={''}
                />
                {
                    data.length > 0 ? (
                        <S.ListBx>
                            {
                                data.map((item, idx) => (
                                    <StoryItem
                                        key={`team-max-store-${idx}`}
                                        item={item}
                                    />
                                ))
                            }
                        </S.ListBx>
                    ) : (
                        <>
                            <S.Empty>
                                게시글이 없습니다.
                            </S.Empty>
                        </>
                    )
                }
            </S.Inner>
        </S.Wrap>
    )
}

export default TeamMaxStory;