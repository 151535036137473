import styled from "styled-components";

export const MyInfo = styled.div`
    width: 100%;
`

export const MyInfoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    dl {
        display: flex;
        justify-content: flex-start;
        gap: 10px;

        dt {
            font-size: 18px;
            font-weight: 700;
        }

        dd {
            font-size: 18px;
            font-weight: 700;
        }
    }
`