import axios from "./axios";

export const GetCalculatorData = async (num: number) => {
    const {data} = await axios.get(`/calculator/item`, {
        params: {
            num,
        }
    });

    return data;
}

export const GetCalculatorList = async (listNum: number) => {
    const {data} = await axios.get('/calculator/list', {
        params: {
            listNum
        }
    });
    return data;
}

export const GetProcess = async (packageId: number) => {
    const {data} = await axios.get('/calculator/package', {
        params: {
            packageId
        }
    });

    return data;
}

export const GetRental = async () => {
    const {data} = await axios.get("/calculator/rental");
    return data;
}

export const GetMore = async () => {
    const {data} = await axios.get("/calculator/more");
    return data;
}

export const Order = async (params: any) => {
    const {data} = await axios.post("/calculator/order", {
        data: params.orderData
    });

    return data;
}

export const Calculator = async () => {
    const {data} = await axios.get(`/calculator/list`);
    return data;
}