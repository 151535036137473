import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useQuery} from "@tanstack/react-query";
import {getAxios, getAxiosDelete, Upload} from "./apiLink";
import {StoryGetContentType} from "../types/story";
import {TeamMaxStoreType} from "../types/main";

export const useStoryList = () => {
    const {data} = useQuery({
        queryFn: () => getAxios('/story/list'),
        queryKey: ['story-list'],
        staleTime: 60 * 60 * 1000,
    });

    return data;
}

export const useStoryMainList = () => {
    const {data} = useQuery<TeamMaxStoreType[]>({
        queryFn: () => getAxios('/story/main-list'),
        queryKey: ['story-main-list'],
        staleTime: 60 * 60 * 1000,
    });

    return data;
}

export const useStoryDelete = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (id: number) => getAxiosDelete('/story/content', {
            id
        }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["story-list"]
            });

            alert("삭제되었습니다.");
        }
    })
}

export const useStorySend = (onComplete: () => void) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (sendData: any) => Upload(sendData),
        onSuccess: async () => {
            const keys: string[] = ["story-list", "story-main-list"];
            await Promise.all(
                keys.map((key) =>
                    queryClient.invalidateQueries({
                        queryKey: [key]
                    })
                )
            );

            alert("등록되었습니다.");
            onComplete();
        }
    })
}

export const useStoryContent = (id: string | undefined) => {
    const {data} = useQuery<StoryGetContentType>({
        queryFn: () => getAxios('/story/content', {
            id
        }),
        queryKey: ['story-content', id],
        staleTime: 60 * 60 * 1000,
        enabled: !!id,
    });

    return data;
}