import styled from "styled-components";
import media from "../../../lib/media";

export const UserDataBx = styled.div`
    dl {
        cursor: pointer;
        dt {
            font-size: 34px;
            font-weight: 700;
            margin-bottom: 20px;
        }

        dd {
            font-size: 24px;
            font-weight: 700;

            span {
                margin-left: 2px;
                font-size: 18px;
            }
        }
    }

    ${media.mobile`
        dl{
            dt{
                font-size: 24px;
                margin-bottom: 5px;
            }
            dd{
                font-size: 14px;
                span{
                font-size: 14px;
            }
        }
    `};
`

export const Logout = styled.div`
    display: flex;
    margin-top: 10px;
    
    button{
        font-size: 14px;
        font-weight: 700;
        color: red;
        border-bottom: 1px solid red;
    }
    
    ${ media.tablet`
        button{
            font-size: 12px;
        }
    ` };
`