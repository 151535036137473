import Payment from "../../components/payment";
import Layout from "../../components/layout";

const PaymentPage = () => {
    return (
        <Layout>
            <Payment/>
        </Layout>
    )
}

export default PaymentPage;