import Master from "../../../components/recreation/master";
import Layout from "../../../components/layout";

const MasterPage = () => {
    return (
        <Layout>
            <Master/>
        </Layout>
    )
}

export default MasterPage;