import * as S from './styles';
import PackageItem from "./package-item";
import TitleBx from "../../common/title-bx";
import {PackageItemsType} from "../../../types/main";


interface Props {
    title: string;
    description: string;
    itemData: PackageItemsType[];
}

const DivePackage = ({title, description, itemData}: Props) => {
    return (
        <S.Wrap>
            <S.Inner>
                <TitleBx
                    title={title}
                    description={description}
                />
                <S.ItemBx>
                    {
                        itemData.map((item, idx) => (
                            <PackageItem
                                key={`package-item-${idx}`}
                                item={item}
                            />
                        ))
                    }
                </S.ItemBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default DivePackage;