import AboutContent from "../../../components/team-max/about";
import Layout from "../../../components/layout";

const AboutPage = () => {
    return(
        <Layout>
            <AboutContent />
        </Layout>
    )
}

export default AboutPage;