import styled from "styled-components";
import media from "../../../lib/media";

export const Wrap = styled.div`

`

export const Inner = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 100px 0;
`

export const StoryBx = styled.div`
    margin-top: 70px;
`

export const StoryContent = styled.div`
    margin-bottom: 50px;
`

export const StoryImages = styled.div`
    text-align: center;
    font-size: 0;

    img {
        display: inline-block;
        margin-bottom: 10px;
        max-width: 100%;
    }

    ${media.mobile`
        img{
            margin-bottom: 5px;    
        }
    `};
`