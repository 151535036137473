import My from "../../components/my";
import Layout from "../../components/layout";
const MyPage = () => {
    return(
        <Layout>
            <My />
        </Layout>
    )
}

export default MyPage;