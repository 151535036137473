import styled from "styled-components";
import media from "../../../lib/media";

export const ListBx = styled.div`
    table {
        width: 100%;
        table-layout: fixed;
    }

    tr {
        th {
            padding: 20px 0;
            border-bottom: 1px solid #eeeeee;
            font-size: 16px;
            font-weight: 700;
            color: #383838;
            overflow: hidden;

            &:nth-child(1) {
                width: 100px;
            }

            &:nth-child(3) {
                width: 100px;
            }

            &:nth-child(4) {
                width: 100px;
            }

            &:nth-child(5) {
                width: 50px;
            }
        }

        td {
            padding: 20px 0;
            border-bottom: 1px solid #eeeeee;
            font-size: 14px;
            font-weight: 600;
            color: #383838;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            a {
                font-size: 14px;
                font-weight: 600;
                color: #383838;
                display: block;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &:nth-child(1) {
                text-align: center;
            }

            &:nth-child(2) {
                text-align: left;
                padding: 0 10px;
                box-sizing: border-box;
            }

            &:nth-child(3) {
                text-align: center;
            }

            &:nth-child(4) {
                text-align: center;
            }

            &:nth-child(5) {
                text-align: center;
            }
        }
    }

    ${media.mobile`
        table{}
        tr{
            th{
                &:nth-child(1) {
                    width: 30px;
                }
    
                &:nth-child(3) {
                    width: 50px;
                }
                
                &:nth-child(4) {
                    width: 70px;
                }
                &:nth-child(5) {
                    width: 30px;
                }
            }
        }
    `};
`

export const Empty = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
`

export const Delete = styled.button`
    font-weight: 700;
    border-bottom: 1px solid red;
    color: red;
`
