import Layout from "../../../components/layout";
import NewsContent from "../../../components/news/news-content";
const NewsContentPage = () => {
    return(
        <Layout>
            <NewsContent />
        </Layout>
    )
}

export default NewsContentPage;