import * as S from "./styles";
import {useCallback, useState} from "react";
import BtnDefault from "../../common/btn-default";
import {useNavigate} from "react-router-dom";
import {useLoadingContext} from "../../loading-provider";
import QuillEditor from "../../notice/notice-write/quill-editor";
import {StorySendType} from "../../../types/story";
import {useAuthContext} from "../../auth-provider";
import {useStorySend} from "../../../api/useStory";

const StoryWrite = () => {
    const {user} = useAuthContext();
    const {showLoading, closeLoading} = useLoadingContext();

    const navigate = useNavigate();
    const [images, setImages] = useState<any[]>([]);
    const [previews, setPreviews] = useState<string[]>([]);

    const [sendData, setSendData] = useState<StorySendType>({
        title: '',
        content: '',
    });

    const onChange = <K extends keyof StorySendType>(value: string, key: K) => {
        setSendData({
            ...sendData,
            [key]: value
        });
    }

    const onChangeImage = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = Array.from(e.target.files);
            const previewImages: string[] = [];
            files.forEach((file) => {
                previewImages.push(URL.createObjectURL(file));
            });

            const copyPreviewImages = previews.concat();
            copyPreviewImages.push(...previewImages);

            const copyFiles = images.concat();
            copyFiles.push(...files);

            setPreviews(copyPreviewImages);
            setImages(copyFiles);
        }
    }, [images, previews]);

    const {mutate} = useStorySend(() => {
        closeLoading();
        navigate(`/my/story`);
    });

    const onClick = async () => {
        if (!images) {
            alert("이미지를 선택해주세요.");
            return;
        }

        const formData = new FormData();
        formData.append('title', sendData.title);
        formData.append('content', sendData.content);
        formData.append('userId', user.id);
        images?.forEach((image) => {
            formData.append("images", image);
        });

        showLoading('게시글 업로드중입니다.');
        mutate(formData);
    }

    const onClickDeleteImage = useCallback((idx: number) => {
        const copyImages = images.concat();
        copyImages.splice(idx, 1);

        setImages(copyImages);

        const copyPreviewImages = previews.concat();
        copyPreviewImages.splice(idx, 1);

        setPreviews(copyPreviewImages);

    }, [images, previews]);


    return (
        <S.Wrap>
            <S.Inner>
                <S.Header>
                    <dl>
                        <dt>TEAM MAX STORY</dt>
                        <dd></dd>
                    </dl>
                </S.Header>

                <S.Bx>
                    <S.Title>
                        <input
                            placeholder={'공지사항 제목을 입력해주세요.'}
                            type="text"
                            value={sendData.title}
                            onChange={(e) => onChange(e.target.value, "title")}
                        />
                    </S.Title>
                    <QuillEditor type="NORMAL" onChangeText={onChange}/>
                    {
                        previews && previews.length > 0 && <S.PreviewBx>
                            <ul>
                                {
                                    previews.map((item, idx) => (
                                        <li key={`preview-${idx}`}>
                                            <button onClick={() => onClickDeleteImage(idx)}>
                                                <img src="/icon/ic_btn_close_white.svg" alt=""/>
                                            </button>
                                            <img src={item} alt=""/>
                                        </li>
                                    ))
                                }
                            </ul>
                        </S.PreviewBx>
                    }

                    <S.ImgUpload>
                        <label htmlFor="upload">이미지 업로드</label>
                        <input id="upload" type="file" accept="image/*" multiple onChange={onChangeImage}/>
                    </S.ImgUpload>
                </S.Bx>

                <S.BtnUpload>
                    <BtnDefault title={`등록`} onClick={onClick}/>
                </S.BtnUpload>
            </S.Inner>
        </S.Wrap>
    )
}

export default StoryWrite;