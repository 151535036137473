import * as S from "./styles";
import QuillEditor from "../../notice/notice-write/quill-editor";
import BtnDefault from "../../common/btn-default";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {NewsSendType} from "../../../types/news";
import {useAuthContext} from "../../auth-provider";
import {useNewsSend} from "../../../api/useNews";

const NewsWrite = () => {
    const {user} = useAuthContext();
    const navigator = useNavigate();
    const {mutate} = useNewsSend(() => {
        navigator("/news/list");
    });

    const [sendData, setSendData] = useState<NewsSendType>({
        title: '',
        content: '',
    });

    const onChange = (value: string | number, key: keyof NewsSendType) => {
        setSendData({
            ...sendData,
            [key]: value
        });
    }

    const send = () => {
        if (sendData.title === '') {
            alert("제목을 입력해주세요.")
            return;
        }

        if (sendData.content === '') {
            alert("내용을 입력해주세요.");
            return;
        }

        mutate(sendData);
    }

    useEffect(() => {
        if (user) {
            onChange(+user.id, "id");
        }
    }, [user]);

    return (
        <S.Wrap>
            <S.Inner>
                <S.Container>
                    <S.Header>
                        <dl>
                            <dt>공지사항</dt>
                            <dd></dd>
                        </dl>
                    </S.Header>

                    <S.Bx>
                        <S.Title>
                            <input
                                placeholder={'공지사항 제목을 입력해주세요.'}
                                type="text"
                                value={sendData.title}
                                onChange={(e) => onChange(e.target.value, "title")}
                            />
                        </S.Title>
                        <QuillEditor type="NORMAL" onChangeText={onChange}/>
                    </S.Bx>

                    <S.PasswordBx>
                        <dl>
                            <dt>
                            </dt>
                            <dd>
                                <BtnDefault title={'등록하기'} onClick={send}></BtnDefault>
                            </dd>
                        </dl>
                    </S.PasswordBx>
                </S.Container>
            </S.Inner>
        </S.Wrap>
    )
}

export default NewsWrite;