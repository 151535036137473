import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getAxios, getAxiosDelete, postNews} from "./apiLink";
import {NewsSendType} from "../types/news";

export const useNewsList = () => {
    const {data} = useQuery({
        queryFn: () => getAxios('/news/list'),
        queryKey: ['news-list'],
        staleTime: 60 * 60 * 1000,
    });

    return data;
}

export const useNewsMainlist = () => {
    const {data} = useQuery({
        queryFn: () => getAxios('/news/main-list'),
        queryKey: ['news-main-list'],
        staleTime: 60 * 60 * 1000,
    });

    return data;
}

export const useNewsContent = (id: string | undefined) => {
    const {data} = useQuery({
        queryFn: () => getAxios("/news/content", {id}),
        queryKey: ['news-content', id],
        staleTime: 60 * 60 * 1000,
    });

    return data;
}

export const useNewsSend = (callback: () => void) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (newData: NewsSendType) => postNews("/news/send", newData),
        onSuccess: async () => {
            const keys: string[] = ["news-list", "news-main-list"];
            await Promise.all(
                keys.map((key) =>
                    queryClient.invalidateQueries({
                        queryKey: [key]
                    })
                )
            );

            alert("등록되었습니다.");
            callback();
        }
    })
}

export const useNewsDelete = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (id: number) => getAxiosDelete("/news/content", {id}),
        onSuccess: async () => {
            const keys: string[] = ["news-list", "news-main-list"];
            await Promise.all(
                keys.map((key) =>
                    queryClient.invalidateQueries({
                        queryKey: [key]
                    })
                )
            );

            alert("삭제되었습니다.");
        }
    });
}

