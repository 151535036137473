import Login from "../../components/login";
import Layout from "../../components/layout";

const LoginPage = () => {
    return(
        <Layout>
            <Login></Login>
        </Layout>
    )
}

export default LoginPage;