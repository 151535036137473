import Nitrox from "../../../components/recreation/nitrox";
import Layout from "../../../components/layout";

const NitroxPage = () => {
    return (
        <Layout>
            <Nitrox/>
        </Layout>
    )
}

export default NitroxPage;