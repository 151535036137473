import Layout from "../../../components/layout";
import FundivingLodgment from "../../../components/recreation/fundiving-lodgment";
const FunDivingLodgmentPage = () => {
    return(
        <Layout>
            <FundivingLodgment />
        </Layout>
    )
}

export default FunDivingLodgmentPage;