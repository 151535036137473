import styled from "styled-components";
import media from "../../../../lib/media";

export const Wrap = styled.div`

`

export const Inner = styled.div<{$type: string}>`
    position: relative;

    .ql-editor {
        height: 300px;
        padding-top: ${p => p.$type === "NORMAL" ? '10px' : '110px'};
        
        img{
            max-width: 100%;
            height: auto;
            display: block;
            margin: 10px auto;
        }
    }
    
    ${media.mobile`
        .ql-editor{
            height: 250px;
        }
    `};
`

export const SampleMessage = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(100% - 301px);
    box-sizing: border-box;
    padding: 10px 15px 0;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    background-color: #ffffff;
    z-index: 10;

    span {
        border-bottom: 1px dashed #777777;
        display: block;
        padding-bottom: 7px;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: #777777;
    }

    ${media.mobile`
        top: calc( 100% - 251px );
        span{
            font-size: 12px;
            line-height: 14px;
        }
    `};
`