import {ReactNode} from "react";
import * as S from "./styles";
import Header from "../common/header";
import Footer from "../common/footer";

interface Props {
    children: ReactNode
}

const Layout = ({children}: Props) => {
    return (
        <S.Wrap>
            <S.Inner>
                <S.Container>
                    <Header/>
                    <S.ContentBx>{children}</S.ContentBx>
                    <Footer/>
                </S.Container>
            </S.Inner>
        </S.Wrap>
    )
}

export default Layout;