import * as S from "./styles";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { useAuthContext } from "../../auth-provider";

interface Props {
    list: any;
    link: string;
    onDelete?: (id: string) => void;
}

const Board = ({ list, link, onDelete }: Props) => {
    const { user } = useAuthContext();

    return (
        <S.ListBx>
            <table>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>제목</th>
                        <th>이름</th>
                        <th>날짜</th>
                        {
                            user && user.admin === 1 && onDelete && <th>관리</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        list && list.length > 0 ? (
                            <>
                                {
                                    list.map((item: any, idx: number) => (
                                        <tr key={`news-list-${idx}`}>
                                            <td>{item.id}</td>
                                            <td>
                                                <Link to={`${link}${item.id}`}>{item.title}</Link>
                                            </td>
                                            <td>{item.name}</td>
                                            <td>{moment(item.createdAt).format("YY/MM/DD")}</td>
                                            {
                                                user && user.admin === 1 && onDelete && <td>
                                                    <S.Delete onClick={() => onDelete(item.id)}>삭제</S.Delete>
                                                </td>
                                            }
                                        </tr>
                                    ))
                                }
                            </>
                        ) : (
                            <tr>
                                <td></td>
                                <td>
                                    <S.Empty>
                                        내용이 없습니다.
                                    </S.Empty>
                                </td>
                                <td></td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </S.ListBx>
    )
}

export default Board;