import BtnDefault from "../../common/btn-default";
import Board from "../../common/board";
import * as S from "./styles";
import {useNewsDelete, useNewsList} from "../../../api/useNews";

const MyNews = () => {
    const list = useNewsList();
    const {mutate} = useNewsDelete();

    if (!list) return null;

    return (
        <div>
            <Board list={list} link={'/news/content/'} onDelete={(id: string) => mutate(+id)}/>
            <S.BtnBx>
                <BtnDefault title={'작성하기'} link={'/news/write'}/>
            </S.BtnBx>
        </div>
    )
}

export default MyNews;