import * as S from "./styles";
import React from "react";
import BtnDefault from "../../common/btn-default";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../../auth-provider";
import Board from "../../common/board";
import {useNoticeList} from "../../../api/useNotice";

export const NoticeList = () => {
    const navigate = useNavigate();
    const {user} = useAuthContext();
    const list = useNoticeList();

    return (
        <S.Wrap>
            <S.Inner>
                <S.NoticeHeader>
                    <dl>
                        <dt>교육 & 펀다이빙 문의</dt>
                    </dl>
                </S.NoticeHeader>

                <Board list={list} link={"/notice/content/"}></Board>
                <S.BtnBx>
                    <BtnDefault
                        onClick={() => navigate(user ? '/notice/write' : `/login?referer=/notice/list`)}
                        title={"문의 작성하기"}
                    />
                </S.BtnBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default NoticeList;