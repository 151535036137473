import OpenwaterThrifty from "../../../components/recreation/openwater-thrifty";
import Layout from "../../../components/layout";

const OpenWaterThriftyPage = () => {
    return(
        <Layout>
            <OpenwaterThrifty />
        </Layout>
    )
}

export default OpenWaterThriftyPage;