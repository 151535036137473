import Steff from "../../../components/team-max/steff";
import Layout from "../../../components/layout";

const SteffPage = () => {
    return (
        <Layout>
            <Steff/>
        </Layout>
    )
}

export default SteffPage;