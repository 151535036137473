import Board from "../../common/board";
import BtnDefault from "../../common/btn-default";
import styled from "styled-components";
import media from "../../../lib/media";
import {useStoryDelete, useStoryList} from "../../../api/useStory";

const BtnBx = styled.div`
    text-align: center;
    margin-top: 100px;

    ${media.mobile`
        margin-top: 30px;
    `};
`

const MyTeammaxStory = () => {
    const {mutate} = useStoryDelete();
    const list = useStoryList();

    const onDelete = async (id: string) => {
        try {
            mutate(+id);
        } catch (error) {
            console.log(error);
        }
    }

    if (!list) return null;

    return (
        <div>
            <Board list={list} link={'/story/content/'} onDelete={onDelete}></Board>
            <BtnBx>
                <BtnDefault title={'작성하기'} link={'/story/write'}/>
            </BtnBx>
        </div>
    )
}

export default MyTeammaxStory;