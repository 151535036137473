import Rescue from "../../../components/recreation/rescue";
import Layout from "../../../components/layout";

const RescuePage = () => {
    return (
        <Layout>
            <Rescue />
        </Layout>
    )
}

export default RescuePage;