import axios from "axios";
import {ErrorMessage} from "../lib/error";

export const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:3090" : "https://api.teammaxdive.com";
const instance = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json', // 기본 헤더 설정
    },
});

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const errorMessage = ErrorMessage(error);
        if (errorMessage) {
            console.log(errorMessage);
        }

        if (error.response.status === 404) {
            console.log("404");
        }

        return Promise.reject(error);
    }
);

export default instance;