import {createContext, ReactNode, useCallback, useContext, useState} from "react";
import {AnimatePresence} from "framer-motion";
import * as S from "./styles";

export const LoadingContext = createContext({
    showLoading(message: string) {
    },
    closeLoading() {
    }
});

interface Props {
    children: ReactNode
}

const LoadingProvider = ({children}: Props) => {
    const [isShow, setIsShow] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState<string>('로딩중입니다..');

    const showLoading = useCallback((message: string) => {
        setIsShow(true);
        setLoadingMessage(message);
    }, []);

    const closeLoading = useCallback(() => {
        setIsShow(false);
    }, []);

    return (
        <LoadingContext.Provider value={{showLoading, closeLoading}}>
            <AnimatePresence>
                {
                    isShow && <S.Wrap>
                        <S.Inner>
                            <S.Container>
                                <S.ContentBx
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    exit={{opacity: 0}}
                                    transition={{duration: 0.2, ease: 'easeOut'}}
                                >
                                    <S.SpinnerBox>
                                        <div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </S.SpinnerBox>
                                    <S.Message>{loadingMessage}</S.Message>
                                </S.ContentBx>
                            </S.Container>

                            <S.Dimmed
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}
                                transition={{duration: 0.2, ease: 'easeOut'}}
                            />
                        </S.Inner>
                    </S.Wrap>
                }

            </AnimatePresence>
            {children}
        </LoadingContext.Provider>
    )
}

export const useLoadingContext = () => useContext(LoadingContext);

export default LoadingProvider;