import styled from "styled-components";

export const Menu = styled.li<{ $isSelect: boolean }>`
    button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 15px;
        font-weight: ${p => p.$isSelect ? 700 : 500};

        img {
            width: 14px;
        }
    }
`;