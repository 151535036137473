import {useAuthContext} from "../../auth-provider";
import * as S from "./styles";

const MyContent = () => {
    const { user } = useAuthContext();
    return(
        <S.MyInfo>
            <S.MyInfoContent>
                <dl>
                    <dt>이름 :</dt>
                    <dd>{user.userName}</dd>
                </dl>

                <dl>
                    <dt>이메일 :</dt>
                    <dd>{user.email}</dd>
                </dl>

                <dl>
                    <dt>Provider :</dt>
                    <dd>{user.provider}</dd>
                </dl>
            </S.MyInfoContent>
        </S.MyInfo>
    )
}

export default MyContent;