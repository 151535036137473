import {createContext, ReactNode, useContext } from "react";
import {useLogout, useUser} from "../../api/useUser";

interface AutoContextType {
    user: any;
    logout: () => void;
}

export const AuthContext = createContext<AutoContextType | null>(null);

interface Props {
    children: ReactNode
}

const AuthProvider = ({children}: Props) => {
    let user = useUser();
    const {mutate} = useLogout(() => {
        user = null;
    });

    const logout = () => {
        mutate();
    }

    return (
        <AuthContext.Provider value={{user, logout}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = (): AutoContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

export default AuthProvider;