import { useParams } from "react-router-dom";
import * as S from "./styles";
import moment from "moment";
import BtnDefault from "../../common/btn-default";
import 'react-quill/dist/quill.snow.css';
import { useNewsContent } from "../../../api/useNews";

const NewsContent = () => {
    const { id } = useParams();
    const newsData = useNewsContent(id);

    if (!newsData) return null;

    return (
        <S.Wrap>
            <S.Inner>
                <S.Container>
                    <S.Header>
                        <dl>
                            <dt>공지 사항</dt>
                        </dl>
                    </S.Header>
                </S.Container>

                <S.Bx>
                    <S.TitleBx>
                        <span>{newsData.title}</span>
                        <span>{moment(newsData.createdAt).format("YYYY/MM/DD")}</span>
                    </S.TitleBx>

                    <S.Content
                        className={'ql-editor'}
                        dangerouslySetInnerHTML={{ __html: newsData.content }}
                    />

                    <S.BtnBx>
                        <BtnDefault link={"/news/list"} title={'확인'} />
                    </S.BtnBx>
                </S.Bx>
            </S.Inner>
        </S.Wrap>
    )
}

export default NewsContent;