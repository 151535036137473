import NoticeWrite from "../../../components/notice/notice-write";
import Layout from "../../../components/layout";

const NoticeWritePage = () => {
    return (
        <Layout>
            <NoticeWrite/>
        </Layout>
    )
}

export default NoticeWritePage;