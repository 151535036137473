import Cpr from "../../../components/recreation/cpr";
import Layout from "../../../components/layout";

const CprPage = () => {
    return (
        <Layout>
            <Cpr/>
        </Layout>
    )
}

export default CprPage;