import Instructor from "../../../components/instructor";
import Layout from "../../../components/layout";

const InstructorPage = () => {
    return (
        <Layout>
            <Instructor/>
        </Layout>
    )
}

export default InstructorPage;