import FunDiving from "../../components/fundiving";
import Layout from "../../components/layout";

const FunDivingPage = () => {
    return (
        <Layout>
            <FunDiving/>
        </Layout>
    )
}

export default FunDivingPage;