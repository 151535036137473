import styled from "styled-components";

export const Wrap = styled.div`
    
`

export const Inner = styled.div`
    
`

export const ContentListBx = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid #cecece;
`

export const Empty = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    padding: 100px 0;
    
    p{
        font-size: 18px;
        font-weight: 600;
    }
`