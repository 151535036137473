import Layout from "../../../components/layout";
import NewsWrite from "../../../components/news/news-write";

const NewsWritePage = () => {
    return (
        <Layout>
            <NewsWrite />
        </Layout>
    )
}

export default NewsWritePage;