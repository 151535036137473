import OpenwaterAdvanced from "../../../components/recreation/openwater-advanced";
import Layout from "../../../components/layout";

const OpenWaterAdvencedPage = () => {
    return (
        <Layout>
            <OpenwaterAdvanced/>
        </Layout>
    )
}

export default OpenWaterAdvencedPage;