import {useParams} from "react-router-dom";
import * as S from "./styles";
import TitleBx from "../../common/title-bx";
import 'react-quill/dist/quill.snow.css';
import {useStoryContent} from "../../../api/useStory";

const StoryContent = () => {
    const {id} = useParams();
    const data = useStoryContent(id);

    if (!data) return null;

    return (
        <S.Wrap>
            <S.Inner>
                <TitleBx
                    title={'STORY'}
                    description={'팀 맥스 다이버 이야기'}
                />

                <S.StoryBx>
                    <S.StoryContent
                        className={'ql-editor'}
                        dangerouslySetInnerHTML={{__html: data.content}}
                    >
                    </S.StoryContent>
                    <S.StoryImages>
                        {
                            data.images?.map((item, idx) => (
                                <img key={`story-images-${idx}`} src={item.url} alt=""/>
                            ))
                        }
                    </S.StoryImages>
                </S.StoryBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default StoryContent;