import * as S from "./styles";
import Board from "../../common/board";
import {useNewsList} from "../../../api/useNews";

const NewsList = () => {
    const list = useNewsList();

    return (
        <S.Wrap>
            <S.Inner>
                <S.ContentBx>
                    <S.TitleBx>
                        <h2>공지사항</h2>
                    </S.TitleBx>
                    <Board list={list} link={"/news/content/"}/>
                </S.ContentBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default NewsList;