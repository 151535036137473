import React, {useState, useRef, useMemo} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";  // Quill 스타일

const Test = () => {
    const [editorValue, setEditorValue] = useState(""); // 에디터 내용
    const quillRef = useRef<ReactQuill>(null); // Quill 컴포넌트 참조

    // 이미지 삽입 핸들러
    const imageHandler = () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input?.files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64Image = reader.result as string;
                    const editor = quillRef.current?.getEditor();
                    if (editor) {
                        const range = editor.getSelection();
                        if (range) {
                            // 유효한 range가 있을 경우에만 insertEmbed 호출
                            editor.insertEmbed(range.index, "image", base64Image); // 이미지 삽입
                            setEditorValue(editor.root.innerHTML); // editorValue 업데이트
                        } else {
                            // 선택된 범위가 없을 경우, 기본 index 0 사용
                            const index = editor.getLength(); // 문서 끝에 삽입
                            editor.insertEmbed(index, "image", base64Image); // 이미지 삽입
                            setEditorValue(editor.root.innerHTML); // editorValue 업데이트
                        }
                    }
                };

                reader.readAsDataURL(file); // 파일을 Base64로 변환
            }
        };
    };

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    ["image"],
                    [{header: [1, 2, 3, 4, 5, false]}],
                    ["bold", "underline"],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
        };
    }, []);

    // onBlur에서 상태를 업데이트하도록 설정
    const handleEditorBlur = () => {
        const editor = quillRef.current?.getEditor();
        if (editor) {
            setEditorValue(editor.root.innerHTML); // editorValue 업데이트
        }
    };

    return (
        <div>
            <ReactQuill
                ref={quillRef}
                onBlur={handleEditorBlur} // onBlur 이벤트로 상태 업데이트
                modules={modules}
                style={{height: "300px", width: "100%"}}
            />
        </div>
    );
};

export default Test;