import * as S from "./styles";
import {ContentType} from "../../../types/my";
import {TitlesData} from "../data";

interface Props {
    contentType: keyof ContentType;
    onClick: (type: keyof ContentType) => void;
    menuType: keyof ContentType;
}

const MyMenus = ({contentType, onClick, menuType}: Props) => {
    return (
        <S.Menu $isSelect={contentType === menuType}>
            <button onClick={() => onClick(menuType)}>
                {TitlesData[menuType]}
                <img src="/icon/ic_arrow_right.svg" alt=""/>
            </button>
        </S.Menu>
    )
}

export default MyMenus;