import PriceContent from "../../../components/price-content";
import Layout from "../../../components/layout";

const PricePage = () => {
    return (
        <Layout>
            <PriceContent/>
        </Layout>
    )
}

export default PricePage;