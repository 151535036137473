import NoticeContent from "../../../components/notice/notice-content";
import Layout from "../../../components/layout";

const NoticeContentPage = () => {
    return (
        <Layout>
            <NoticeContent/>
        </Layout>
    )
}

export default NoticeContentPage;