import styled from "styled-components";
import {keyframes} from "styled-components";
import {motion} from "framer-motion";

export const Wrap = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow-y: auto;
    overscroll-behavior: contain;
    -ms-overflow-style: none;
    scrollbar-width: none;
`

export const Inner = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% + 1px);
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Container = styled.div`

`

export const ContentBx = styled(motion.div)`
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 15px;
`

export const Dimmed = styled(motion.div)`
    background-color: rgbA(0, 0, 0, 0.02);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`

export const Message = styled.div`
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: #999999;
`

export const Pulse = keyframes`
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: .25;
        transform: scale(.75);
    }
`
export const SpinnerBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    > div {
        width: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.5);

            &:nth-child(1) {
                animation: ${Pulse} .4s ease 0s infinite alternate;
            }

            &:nth-child(2) {
                animation: ${Pulse} .4s ease .2s infinite alternate;
            }

            &:nth-child(3) {
                animation: ${Pulse} .4s ease .4s infinite alternate;
            }
        }
    }
`
