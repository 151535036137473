import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getAxios, postNotice} from "./apiLink";

export const useNoticeMainList = () => {
    const {data} = useQuery({
        queryFn: () => getAxios('/notice/main-list'),
        queryKey: ['notice-main-list'],
        staleTime: 60 * 60 * 1000
    });

    return data;
}

export const useNoticeList = () => {
    const {data} = useQuery({
        queryFn: () => getAxios('/notice/notice-list'),
        queryKey: ['notice-list'],
        staleTime: 60 * 60 * 1000
    });

    return data;
}

export const useMyNotice = (userId: number) => {
    const {data} = useQuery({
        queryFn: () => getAxios("/notice/my-notice-list", {
            userId
        }),
        queryKey: ['my-notice-list'],
        staleTime: 60 * 60 * 1000
    });

    return data;
}

export const useNoticeContent = (id: string | undefined) => {
    const {data} = useQuery({
        queryFn: () => getAxios("/notice/content", {
            id
        }),
        queryKey: ['notice-content', id],
        staleTime: 0,
        enabled: !!id
    });

    return data;
}

export const useNoticePassword = (id: string | undefined, password: string) => {
    return useQuery({
        queryFn: () => getAxios('/notice/password', {id, password}),
        queryKey: ['notice-password'],
        enabled: false,
        staleTime: 0,
    });
}

interface noticeSendType {
    sendData: any;
    calculatorData: any;
    userId: number | null
}

export const useNoticeSend = (callback: () => void) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({
                         sendData: noticeData,
                         calculatorData: data,
                         userId
                     }: noticeSendType) => postNotice('/notice/send', {
            noticeData, data, userId
        }),
        onSuccess: async () => {
            const keys: string[] = ["notice-list", "my-notice-list"];
            await Promise.all(
                keys.map((key) =>
                    queryClient.invalidateQueries({
                        queryKey: [key],
                    })
                )
            )

            alert("등록되었습니다.");
            callback();
        }
    })
}

export const useNoticeReply = (noticeId: number) => {
    const {data} = useQuery({
        queryFn: () => getAxios('/notice/reply-list', {
            noticeId
        }),
        queryKey: ['notice-reply', noticeId],
        staleTime: 60 * 60 * 1000
    });

    return data;
}

interface NoticeReplyType {
    noticeId: number;
    replyContent: string;
}

export const usePostNoticeReply = (noticeId: number, callback: () => void) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({noticeId, replyContent: value}: NoticeReplyType) => postNotice('/notice/reply', {
            noticeId,
            value
        }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['notice-reply', noticeId]
            });

            callback();
        }
    })
}
