import styled from "styled-components";

export const Wrap = styled.div`
`

export const Inner = styled.div`
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 100px 20px;
    gap: 20px;
`

export const Header = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 70px;

    dl {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        dt {
            font-size: 24px;
            font-weight: 700;
        }

        dd {
            font-size: 18px;
            font-weight: 700;
        }
    }
`

export const ImgUpload = styled.div`
    margin-top: 10px;
    label{
        display: inline-block;
        padding: 8px 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        border-radius: 8px;
        overflow: hidden;
        transition: background-color 0.15s;
        position: relative;
        min-width: 100px;
        text-align: center;
        background-color: #000000;
    }
    
    input{
        display: none;
    }
`

export const Bx = styled.div`
    box-sizing: border-box;
    border-top: none;
`

export const Title = styled.div`
    border-bottom: 1px solid #cecece;
    margin-bottom: 10px;

    input {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        font-size: 22px;
        border: none;

        &::placeholder {
            color: gray;
        }
    }
`

export const PreviewBx = styled.div`
    margin-top: 10px;

    ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 5px;

        li {
            width: 100px;
            height: 100px;
            border-radius: 10px;
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            button {
                position: absolute;
                width: 20px;
                height: 20px;
                right: 3px;
                top: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: red;
                border-radius: 100%;

                img {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
`

export const BtnUpload = styled.div`
    margin-top: 100px;
    display: flex;
    justify-content: center;
`