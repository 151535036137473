import Razor from "../../components/razor";
import Layout from "../../components/layout";

const RazorPage = () => {
    return (
        <Layout>
            <Razor/>
        </Layout>
    )
}


export default RazorPage;