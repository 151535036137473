import styled from "styled-components";
import media from "../../../lib/media";

export const Wrap = styled.div`
    padding: 100px 0;
    ${media.mobile`
        padding-top: 30px;
    `};

`

export const Inner = styled.div`
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 50px;
    box-sizing: border-box;

    ${media.tablet`
        padding: 0 20px;
    `};

`

export const Container = styled.div`
    
`

export const Header = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 70px;

    dl {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        dt {
            font-size: 24px;
            font-weight: 700;
        }

        dd {
            font-size: 18px;
            font-weight: 700;
        }
    }

    ${media.tablet`
        margin-bottom: 30px;
    ` };
`

export const Bx = styled.div`
    box-sizing: border-box;
    border-top: none;
`

export const TitleBx = styled.div`
    border-bottom: 1px solid #cecece;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 15px;

    span{
        &:nth-child(1){
            font-size: 28px;
            font-weight: 600;
            color: #000000;
        }

        &:nth-child(2){
            width: 300px;
            font-size: 15px;
            font-weight: 500;
        }
    }
`

export const Content = styled.div`
    position: relative;
    padding: 30px 10px;
    box-sizing: border-box;
    width: 100%;
`

export const BtnBx = styled.div`
    margin-top: 100px;
    display: flex;
    justify-content: center;
`