import {Routes, Route} from 'react-router-dom';

import MainPage from "./pages/MainPage";
import PaymentPage from "./pages/PaymentPage";

import AboutPage from "./pages/team-max/AboutPage";
import ResortPage from "./pages/team-max/ResortPage";
import ContactPage from "./pages/team-max/ContactPage";
import SteffPage from "./pages/team-max/SteffPage";

import TechnicalInfoPage from "./pages/recreation/TechnicalInfoPage";
import OpenWaterThriftyPage from "./pages/recreation/OpenWaterThriftyPage";
import OpenWaterPage from "./pages/recreation/OpenWaterPage";
import OpenWaterFunPage from "./pages/recreation/OpenWaterFunPage";
import AdvancedPage from "./pages/recreation/AdvancedPage";
import OpenWaterAdvencedPage from "./pages/recreation/OpenWaterAdvencedPage";
import NitroxPage from "./pages/recreation/NitroxPage";
import RescuePage from "./pages/recreation/RescuePage";
import CprPage from "./pages/recreation/CprPage";
import MasterPage from "./pages/recreation/MasterPage";
import FunDivingPage from "./pages/FunDivingPage";
import InstructorPage from "./pages/recreation/InstructorPage";
import FunDivingLodgmentPage from "./pages/recreation/FunDivingLodgmentPage";

import TechnicalPage from "./pages/TechnicalPage";
import RazorPage from "./pages/RazorPage";
import PricePage from "./pages/expense/PricePage";

import CalculatorPage from "./pages/expense/CalculatorPage";
import CompletePage from "./pages/expense/CompletePage";
import CalculatorListPage from "./pages/expense/CalculatorListPage";

import NoticeWritePage from "./pages/notice/NoticeWritePage";
import NoticeListPage from "./pages/notice/NoticeListPage";
import NoticeContentPage from "./pages/notice/NoticeContentPage";

import NewsWritePage from "./pages/news/NewsWritePage";
import NewsListPage from "./pages/news/NewsListPage";
import NewsContentPage from "./pages/news/NewsContentPage";

import AuthProvider from "./components/auth-provider";
import LoginPage from "./pages/LoginPage";
import MyPage from "./pages/MyPage";

import LoadingProvider from "./components/loading-provider";
import PopupProvider from "./components/popup/PopupProvider";
import ToastProvider from "./components/popup-table-component/ToastProvider";

import StoryContentPage from "./pages/story/StoryContentPage";
import StoryWritePage from "./pages/story/StoryWritePage";

import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import Test from "./pages/Test";

const queryClient = new QueryClient();

function App() {

    return (
        <div className='App'>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} buttonPosition={'bottom-left'}/>
                <AuthProvider>
                    <LoadingProvider>
                        <PopupProvider>
                            <ToastProvider>
                                <Routes>
                                    <Route path="/" element={<MainPage/>}/>

                                    <Route path="/login" element={<LoginPage/>}></Route>
                                    <Route path="/my/:contentTypeName" element={<MyPage/>}></Route>

                                    <Route path="/team-max">
                                        <Route path="about" element={<AboutPage/>}/>
                                        <Route path="steff" element={<SteffPage/>}/>
                                        <Route path="resort" element={<ResortPage/>}/>
                                        <Route path="contact" element={<ContactPage/>}/>
                                    </Route>

                                    <Route path="/recreation">
                                        <Route path="technical-info" element={<TechnicalInfoPage/>}/>
                                        <Route path="open-water-thrifty" element={<OpenWaterThriftyPage/>}/>
                                        <Route path="open-water" element={<OpenWaterPage/>}/>
                                        <Route path="open-water-fun" element={<OpenWaterFunPage/>}/>
                                        <Route path="advanced" element={<AdvancedPage/>}/>
                                        <Route path="open-water-advanced" element={<OpenWaterAdvencedPage/>}/>
                                        <Route path="nitrox" element={<NitroxPage/>}/>
                                        <Route path="rescue" element={<RescuePage/>}/>
                                        <Route path="cpr" element={<CprPage/>}/>
                                        <Route path="master" element={<MasterPage/>}/>
                                        <Route path="fundiving-lodgment" element={<FunDivingLodgmentPage/>}/>
                                        <Route path="instructor" element={<InstructorPage/>}/>
                                    </Route>

                                    <Route path="/fundiving" element={<FunDivingPage/>}/>
                                    <Route path="/technical" element={<TechnicalPage/>}/>
                                    <Route path="/razor" element={<RazorPage/>}/>

                                    <Route path="/expense">
                                        <Route path="price" element={<PricePage/>}/>
                                        <Route path="calculator" element={<CalculatorPage/>}/>
                                        <Route path="complete/:num" element={<CompletePage/>}/>
                                        <Route path="list" element={<CalculatorListPage/>}/>
                                    </Route>

                                    <Route path="/notice">
                                        <Route path={"write"} element={<NoticeWritePage/>}></Route>
                                        <Route path={"list"} element={<NoticeListPage/>}></Route>
                                        <Route path={"content/:id"} element={<NoticeContentPage/>}></Route>
                                    </Route>

                                    <Route path={"/news"}>
                                        <Route path={"write"} element={<NewsWritePage/>}></Route>
                                        <Route path={"list"} element={<NewsListPage/>}></Route>
                                        <Route path={"content/:id"} element={<NewsContentPage/>}></Route>
                                    </Route>

                                    <Route path="/payment/:code" element={<PaymentPage/>}/>

                                    <Route path={"/story"}>
                                        <Route path={"write"} element={<StoryWritePage/>}></Route>
                                        <Route path={"content/:id"} element={<StoryContentPage/>}></Route>
                                    </Route>

                                    <Route path="/test" element={<Test/>}></Route>
                                </Routes>
                            </ToastProvider>
                        </PopupProvider>
                    </LoadingProvider>
                </AuthProvider>
            </QueryClientProvider>
        </div>
    )
}

export default App;
