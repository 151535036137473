import Layout from "../../../components/layout";
import StoryContent from "../../../components/story/story-content";

const StoryContentPage = () => {
    return (
        <Layout>
            <StoryContent />
        </Layout>
    )
}

export default StoryContentPage;