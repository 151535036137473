import styled from "styled-components";
import Visual from "./visual";
import TeamAbout from "./team-about";
import DivePackage from "./dive-package";
import Notice from "./notice";
import TeamMaxStory from "./team-max-story";
import QrCode from "./qr-code";
import {DataRecommendation, DataPremiumPackage} from "./dive-package/data";

const Wrap = styled.div`

`

const MainContent = () => {
    return (
        <Wrap>
            <QrCode/>
            <Visual
                title='TEAM MAX DIVE RESORT'
                imgSrc={["/image/img_about_visual_00.jpg"]}
            />
            <TeamAbout/>
            <DivePackage
                title={`팀맥스 추천 알뜰 패키지`}
                description={`팀맥스 다이브 리조트만의 특별한 혜택을 선택해 보세요`}
                itemData={DataRecommendation}
            />
            <DivePackage
                title={`팀맥스 프리미엄 패키지`}
                description={`팀맥스 다이브 리조트만의 특별한 혜택을 선택해 보세요`}
                itemData={DataPremiumPackage}
            />
            <Notice/>
            <TeamMaxStory/>
        </Wrap>
    )
}

export default MainContent;