import Advanced from "../../../components/recreation/advanced";
import Layout from "../../../components/layout";

const AdvancedPage = () => {
    return (
        <Layout>
            <Advanced/>
        </Layout>
    )
}

export default AdvancedPage;