import {InfoListTableType} from "../../types/common";

export const PriceContentData: InfoListTableType[] = [
    {
        title: '',
        subTitle: ['구분', '금액'],
        list: [
            {
                contents: [
                    {
                        description: '펀다이빙 숙박 패키지',
                    },
                    {
                        price: '$150',
                        content: {
                            title: '포함 사항',
                            contentList: [
                                {
                                    title: '숙박',
                                    description: '(2인 1실)',
                                },
                                {
                                    title: '공항픽업',
                                },
                                {
                                    title: '조식/중식',
                                },
                                {
                                    title: '주간 다이빙',
                                    description: '(2~3회)'
                                }
                            ],
                        },
                        descriptionList: [
                            '* 불포함사항 : 석식, 공항드랍, 해양공원 입장료',
                        ]
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '펀다이빙 패키지\n(숙박 제외)',
                    },
                    {
                        price: '$120',
                        content: {
                            title: '포함 사항',
                            contentList: [
                                {
                                    title: '다이빙',
                                    description: '(2~3회)',
                                },
                                {
                                    title: '중식',
                                },
                            ],
                        },
                        descriptionList: [
                            '* 불포함사항 : 해양공원 입장료',
                        ]
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '테크니컬 다이빙 숙박 패키지',
                    },
                    {
                        price: '$170',
                        content: {
                            title: '포함 사항',
                            contentList: [
                                {
                                    title: '숙박',
                                    description: '(2인 1실)',
                                },
                                {
                                    title: '공항픽업',
                                },
                                {
                                    title: '조식/중식',
                                },
                                {
                                    title: '주간 다이빙',
                                },
                                {
                                    title: '더블 or \n사이드 실린더',
                                },
                                {
                                    title: '1개의\n감압기체',
                                },
                            ],
                        },
                        descriptionList: [
                            '* 불포함사항 : 석식,공항드랍,해양공원 입장료, 추가 감압기체 15$ ,헬륨비용 별도',
                        ]
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '테크니컬 다이빙 패키지\n(숙박 제외)',
                    },
                    {
                        price: '$140',
                        content: {
                            title: '포함 사항',
                            contentList: [
                                {
                                    title: '중식',
                                },
                                {
                                    title: '주간 다이빙',
                                },
                                {
                                    title: '더블 or\n사이드 실린더',
                                },
                                {
                                    title: '1개의\n감압기체',
                                },
                            ],
                        },
                        descriptionList: [
                            '* 불포함사항 : 해양공원 입장료, 추가 감압기체 15$,헬륨비용 별도',
                        ]
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '프리다이빙 숙박 패키지',
                    },
                    {
                        price: '$130',
                        content: {
                            title: '포함 사항',
                            contentList: [
                                {
                                    title: '숙박',
                                    description: '(2인 1실)',
                                },
                                {
                                    title: '공항픽업',
                                },
                                {
                                    title: '조식/중식',
                                },
                            ],
                        },
                        descriptionList: [
                            '* 불포함사항 : 석식,해양공원 입장료',
                        ]
                    }
                ]
            },
            {
                contents: [
                    {
                        description: '체험 다이빙 숙박 패키지',
                    },
                    {
                        price: '$150',
                        content: {
                            title: '포함 사항',
                            contentList: [
                                {
                                    title: '숙박',
                                    description: '(2인 1실)',
                                },
                                {
                                    title: '조식/중식',
                                },
                                {
                                    title: '주간 체험다이빙',
                                    description: '(2회)',
                                },
                                {
                                    title: '장비렌탈',
                                },
                            ],
                        },
                        descriptionList: [
                            '* 불포함사항 : 석식',
                        ]
                    }
                ]
            },
        ],
    },
]