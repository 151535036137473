import styled from "styled-components";
import media from "../../lib/media";

export const Wrap = styled.div`

`

export const Inner = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 100px 20px;
    gap: 50px;
    box-sizing: border-box;

    ${media.mobile`
        flex-direction: column;
        padding: 20px 20px;
        gap: 35px;
    `};
`

export const MenuBx = styled.div`
    width: 350px;
    ${media.mobile`
        width: 100%;
    `};
`

export const MenuList = styled.div`
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #eeeeee;

    ul {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 0 10px;
        box-sizing: border-box;
    }

    ${media.mobile`
        padding: 10px 0;
        margin-top: 15px;
        border-bottom: 1px solid #eeeeee;
    `};
`

export const ContentBx = styled.div`
    width: 100%;
`

export const ContentTitle = styled.h2`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 35px;

    ${media.mobile`
        margin-bottom: 10px;
    `};
`

